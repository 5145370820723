import React from "react";
import checkmark from "../images/checkmarkIcon.svg";

interface servicesCardProps {
  title: string;
}

export default function ServicesCard({ title }: servicesCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-md p-3 items-center inline-flex mx-auto w-full">
      <div className="flex items-center">
        <img src={checkmark} className=" h-12 pr-4" alt="checkmark" />
        <div>
          <p className="text-lg text-left font-medium">{title}</p>
        </div>
      </div>
    </div>
  );
}
