import React from "react";
import priceIcon from "../images/priceIcon.png";

interface PriceCardProps {
  title: string;
  subtitle: string;
}

export default function PriceCard({ title, subtitle }: PriceCardProps) {
  return (
    <div className="bg-white rounded-2xl drop-shadow-card mx-auto w-full flex flex-col items-center justify-center">
      <img src={priceIcon} alt="priceIcon" className="h-10 my-4" />
      <div className=" w-full">
        <h2 className="text-lg font-semibold text-white bg-primary py-2 px-4 text-center">
          {title}
        </h2>
        <p className=" text-[32px] font-bold mx-4 mt-2">{subtitle}</p>
        <p className="mb-4">rsd mesečno</p>
      </div>
    </div>
  );
}
