import "../index.css";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import logo from "../images/URlogoWhite.svg";
export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const toggleNavbar = () => setNavbarOpen(!navbarOpen);

  const [color, setColor] = useState(false);

  function changeNavColor() {
    setColor(typeof window !== "undefined" && window.scrollY >= 200);
  }

  useEffect(() => {
    changeNavColor();
    window.addEventListener("scroll", changeNavColor);

    return () => {
      window.removeEventListener("scroll", changeNavColor);
    };
  }, []);
  return (
    <nav
      className={`border-gray-200 fixed w-full z-20 transition-all duration-500 ${
        color ? "bg-primary" : "bg-transparent"
      }`}
    >
      <div className="flex flex-row items-center w-[80%] mx-auto justify-between  py-4">
        <a href="#hero">
          <img src={logo} className="h-10" alt="Knjigovodstvo" />
        </a>
        <button
          className=" text-white flex text-[30px] md:hidden"
          onClick={toggleNavbar}
        >
          {navbarOpen ? (
            <FontAwesomeIcon
              icon={faX}
              onClick={() => {
                setNavbarOpen(false);
              }}
            />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
        <div
          className={`flex justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0  md:relative ${
            navbarOpen
              ? "absolute top-[40px] right-0  w-full shadow-2xl bg-primary"
              : "hidden"
          }`}
        >
          <ul
            className={`items-center justify-center text-white  font-normal  text-xl tracking-wider  space-y-6 md:flex md:space-x-6  md:space-y-0 ${
              navbarOpen ? "mx-auto text-center mt-4" : "text-right"
            }`}
          >
            <li
              className={`${
                color ? "hover:text-footer" : "hover:text-primary"
              }`}
            >
              <a
                href="#about"
                onClick={() => {
                  setNavbarOpen(false);
                }}
              >
                O nama
              </a>
            </li>
            <li
              className={`${
                color ? "hover:text-footer" : "hover:text-primary"
              }`}
            >
              <a
                href="#usluge"
                onClick={() => {
                  setNavbarOpen(false);
                }}
              >
                Usluge
              </a>
            </li>
            <li
              className={`${
                color ? "hover:text-footer" : "hover:text-primary"
              }`}
            >
              <a
                href="#cenovnik"
                onClick={() => {
                  setNavbarOpen(false);
                }}
              >
                Cenovnik
              </a>
            </li>
            <li
              className={`${
                color ? "hover:text-footer" : "hover:text-primary"
              }`}
            >
              <a
                href="#kontakt"
                onClick={() => {
                  setNavbarOpen(false);
                }}
              >
                Kontakt
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
