import React from "react";
import ServicesCard from "./ServicesCard";

const services = [
  { title: "kompletan knjigovodstveni servis" },
  { title: "obrada svih prodajnih faktura, ulaznih računa i bankovnih izvoda" },
  { title: "upravljanje nalogom poslovnih partnera na sistemu eFaktura" },
  { title: "izrada prodajnih faktura putem sistema eFaktura" },
  { title: "vođenje KPO knjige za paušalno oporezovane preduzetnike" },
  { title: "priprema finansijskih izveštaja na kraju godine" },
  { title: "obračun poreza na dobit na kraju godine" },
  { title: "podnošenje godišnjeg finansijskog izveštaja APR-u" },
  { title: "podnošenje poreskih obrazaca na portalu Poreske uprave" },
  { title: "mesečni obračun zarada radnika" },
  { title: "obračun i podnošenje kvartalne/mesečne prijave PDV-a" },
  { title: "neograničena računovostvena podrška" },
];

export default function Services() {
  return (
    <div id="usluge" className="w-full  bg-footer  flex flex-col mx-auto py-20">
      <h2 className="md:text-6xl text-4xl font-bold text-center text-white pb-10 pl-4">
        Knjigovodstvene usluge
      </h2>
      <p className="text-base text-center text-white w-[80%] mx-auto pb-10">
        Usluge koje pruža naša agencija su iz celokupnog spektra u oblasti
        knjigovodstva namenjene svim oblicima organizovanja i vrstama delatnosti
        poslovnih partnera, a koje podrazumevaju:
      </p>
      <div className="grid md:grid-cols-2  grid-cols-1 gap-4 pt-4 w-[80%] mx-auto">
        {services.map((service) => (
          <ServicesCard title={service.title} />
        ))}
      </div>
    </div>
  );
}
