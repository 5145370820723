import React from "react";
import logo from "../images/Logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <div className="bg-footer py-20 w-full " id="kontakt">
      <div className=" xl:w-[80%] w-1/2 mx-auto grid gap-20 grid-cols-1 xl:grid-cols-3">
        <img src={logo} className="h-[65px]" alt="Knjigovodstvo" />
        <div className="text-white text-left flex flex-col space-y-4">
          <div className="flex flex-col sm:flex-row">
            <p className="mr-2 text-white">TELEFON:</p>
            <a
              href="tel:+381 63 76 99 820"
              className=" text-white font-semibold hover:text-primary"
            >
              +381 63 76 99 820
            </a>
          </div>
          <div className="flex flex-col sm:flex-row">
            <p className="mr-2 text-white">EMAIL:</p>
            <a
              href="mailto:ultrarockruma@gmail.com"
              className=" text-white font-semibold hover:text-primary"
            >
              ultrarockruma@gmail.com
            </a>
          </div>
          <div className="flex flex-col 2xl:flex-row 2xl:whitespace-nowrap">
            <p className="mr-2 text-white">ADRESA:</p>
            <a
              href="https://maps.app.goo.gl/u2M8kBc6v6Q5dUQK7"
              className=" text-white font-semibold hover:text-primary"
            >
              Stanka Paunović Veljka 145/6, Ruma 22400
            </a>
          </div>
        </div>
        <div className="flex items-center xl:justify-center mt-4 space-x-6 text-4xl text-white ">
          <a
            href="https://www.facebook.com/profile.php?id=100088808905206&mibextid=2JQ9oc"
            className="hover:text-primary"
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            href="https://www.linkedin.com/in/dalibor-jankovic-a78a6a292/?originalSubdomain=rs"
            className="hover:text-primary"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
      <div className=" w-full border-t mt-20 border-white">
        <div className="flex flex-col-reverse text-center pb-10  lg:flex-row w-[70%] text-lg text-white mx-auto  justify-center pt-5">
          <p className="text-sm text-white">
            Copyright © Ultra Rock 2023. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

// export default function Footer() {
//   return (
//     <div id="footer" className="w-full bg-footer py-20">
//       <div className="xl:w-[80%] w-1/2 mx-auto grid  grid-cols-1 xl:grid-cols-3">
//         <div className="xl:w-[80%] md:w-[60%] w-[80%]  ">
//           <img src={logo} className="h-[65px]" alt="logo" />
//         </div>
//         <div className="space-y-4 md:text-lg text-base">
//           <div className="flex flex-col sm:flex-row">
//             <p className="mr-2 text-white">TELEFON:</p>
//             <a
//               href="tel:+381 63 76 99 820"
//               className=" text-white font-semibold hover:text-primary"
//             >
//               +381 63 76 99 820
//             </a>
//           </div>
//           <div className="flex flex-col sm:flex-row">
//             <p className="mr-2 text-white">EMAIL:</p>
//             <a
//               href="mailto:ultrarockruma@gmail.com"
//               className=" text-white font-semibold hover:text-primary"
//             >
//               ultrarockruma@gmail.com
//             </a>
//           </div>
//           <div className="flex flex-col 2xl:flex-row 2xl:whitespace-nowrap">
//             <p className="mr-2 text-white">ADRESA:</p>
//             <a
//               href="https://maps.app.goo.gl/u2M8kBc6v6Q5dUQK7"
//               className=" text-white font-semibold hover:text-primary"
//             >
//               Stanka Paunović Veljka 145/6, Ruma 22400
//             </a>
//           </div>
//         </div>
//         <div>
//           <div className="flex items-center mt-4 space-x-6 text-4xl text-white ">
//             <a
//               href="https://www.facebook.com/profile.php?id=100088808905206&mibextid=2JQ9oc"
//               className=""
//             >
//               <FontAwesomeIcon icon={faFacebookSquare} />
//             </a>
//             <a
//               href="https://www.linkedin.com/in/dalibor-jankovic-a78a6a292/?originalSubdomain=rs"
//               className="hover:text-primary"
//             >
//               <FontAwesomeIcon icon={faLinkedin} />
//             </a>
//           </div>
//         </div>
//       </div>
//       <div className=" w-full border-t border-white mt-20">
//         <div className="flex flex-col-reverse text-center pb-10  lg:flex-row w-[70%] text-lg text-white mx-auto  justify-center pt-5">
//           <p className="text-sm text-white">
//             Copyright © Ultra Rock 2023. All rights reserved.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }
