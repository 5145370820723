import { useEffect, useState } from "react";
import logo from "../images/Logo.svg";

export default function Hero() {
  const [zoomIn, setZoomIn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setZoomIn(true);
    }, 500);
  }, [zoomIn]);

  return (
    <>
      <div
        id="hero"
        className="w-full h-screen bg-hero_bg_image bg-no-repeat bg-cover lg:bg-center bg-left flex flex-col mx-auto"
      >
        <div
          className={`w-[80%] tracking-wide m-auto pt-[300px]  md:text-[80px] font-bold text-white text-left md:leading-[105px] text-[40px] leading-[48px] transition-transform duration-1000 ${
            !zoomIn ? " scale-0" : ""
          }`}
        >
          <img
            src={logo}
            className="md:h-[65px] h-[35px] mb-4"
            alt="Knjigovodstvo"
          />
          <h1>
            Idealno rešenje <br />
            za vaše knjigovodstvo
          </h1>
        </div>
      </div>
    </>
  );
}
