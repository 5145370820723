import React from "react";
import aboutUs from "../images/AboutUs.png";

export default function AboutUs() {
  return (
    <div
      className="flex 2xl:flex-row flex-col w-[80%] mx-auto py-20 items-center"
      id="about"
    >
      <img
        src={aboutUs}
        className="840:h-[500px] sm:h-[400px]h-[200px] 2xl:pr-16 pr-0 pb-12 2xl:pb-0"
        alt="logo"
      />
      <div>
        <h2 className="md:text-6xl text-4xl font-bold text-left text-primary pb-10 pl-4">
          O nama
        </h2>

        <p className="font-semibold text-left pl-4">
          Trudimo se da svim poslovnim partnerima posvetimo maksimalnu pažnju,
          kao i da pružimo pravovremene i tačne informacije u vezi svega što ih
          interesuje vezano za njihove knjigovodstvene, poreske i ostale
          obaveze.
        </p>
        <div className="grid sm:grid-cols-2 sgrid-cols-1 gap-4 pt-4">
          <div className="p-4">
            <h2 className="text-left pb-2 text-lg font-semibold">
              Pravni okvir
            </h2>
            <p className="text-base text-left">
              Knjigovodstvena agencija ULTRA ROCK ACCOUNTING, posluje u okviru
              firme ULTRA ROCK DOO iz Rume, osnovane 2007. godine. Od kraja
              2022. godine firma ULTRA ROCK DOO je u skladu sa Zakonom o
              računovodstvu upisana u registar pružalaca računovodstvenih usluga
              kod{" "}
              <a
                href="https://www.apr.gov.rs/"
                className=" text-primary font-semibold"
              >
                Agencije za privredne registre Republike Srbije
              </a>
              .
              <h2 className="text-left pb-2 pt-10 text-lg font-semibold">
                Ovlašćeni računovođa firme
              </h2>
              <p className="text-base text-left">
                Glavni knjigovođa agencije je Dalibor Janković, licencirani
                računovođa sa preko dvadeset godina radnog iskustva u
                računovodstvenim i knjigovodstvenim poslovima i član Saveza
                računovođa i revizora Srbije.
              </p>
            </p>
          </div>
          <div className="p-4">
            <h2 className="text-left pb-2 text-lg font-semibold">
              Prednosti saradnje
            </h2>
            <p className="text-base text-left">
              Naša agencija se maksimalno trudi da svi poslovi koje obavlja za
              svoje poslovne partnere, a koji to zakonski dozvoljavaju, budu u
              elektronskom formatu, bez bespotrebnog trošenja vremena i
              sredstava. Svim poslovnim partnerima omogućavamo izbor
              komunikacije sa nama na način koji im najviše odgovara: telefon,
              e-mail, Viber, WhatsApp itd., što znači da smo otvoreni za
              saradnju sa svim partnerima, gde god se oni nalazili.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
