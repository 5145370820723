import React from "react";
import PriceCard from "./PriceCard";

const prices = [
  { title: "PAUŠALCI", subtitle: "5.000" },
  { title: "UDRUŽENJA GRAĐANA", subtitle: "5.000" },
  { title: "POLJOPRIVREDNA GAZDINSTVA", subtitle: "9.000" },
  {
    title: "MIKRO PRAVNA LICA I PREDUZETNICI VAN PDV",
    subtitle: "10.000",
  },
  {
    title: "MIKRO PRAVNA LICA I PREDUZETNICI U PDV",
    subtitle: "15.000",
  },
  {
    title: "KOMPLEKSNIJA I OBIMNIJA PRAVNA LICA I PREDUZETNICI",
    subtitle: "25.000",
  },
  { title: "MALA PRAVNA LICA", subtitle: "40.000" },
  { title: "SREDNJA PRAVNA LICA", subtitle: "100.000 " },
  { title: "VELIKA PRAVNA LICA", subtitle: "150.000" },
];

export default function Prices() {
  return (
    <div
      className="flex flex-col 1560:w-[80%] w-full mx-auto py-20 items-center"
      id="cenovnik"
    >
      <h2 className="md:text-6xl text-4xl font-bold text-center text-primary pb-10 pl-4">
        Cenovnik
      </h2>
      <p className="text-base text-center w-[80%] mx-auto pb-10">
        Cene naših knjigovodstvenih usluga polaze od pretpostavke da svim
        poslovnim partnerima obezbedimo najbolji odnos cene i kvaliteta pružene
        usluge.
      </p>
      <div className="grid xl:grid-cols-3 840:grid-cols-2 grid-cols-1 gap-6 pt-4 w-[80%] mx-auto">
        {prices.map((price) => (
          <PriceCard title={price.title} subtitle={price.subtitle} />
        ))}
      </div>
      <p className="text-base text-left w-[80%] mx-auto pt-10">
        <span className="font-bold">NAPOMENA:</span> Za poslovne partnere koji
        tokom cele godine koriste naše usluge, sastavljanje i predaja
        Finansijskih izveštaja (Završnih računa) za tu godinu se ne naplaćuje
        dodatno. Ukoliko poslovni partner ima neuobičajeno veći obim materijala
        za knjiženje ili ukoliko knjigovodstvene potrebe za tog partnera
        zahtevaju neke dodatne veštine, cena može biti veća od prikazanih, o
        čemu bi poslovni partner bio pravovremeno informisan. <br /> Stoga,
        preporučujemo da nas kontaktirate kako biste saznali preciznu cenu naših
        usluga na mesečnom nivou za vašu firmu, radnju, udruženje...
      </p>
    </div>
  );
}
